<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  courseData: {
    type: Object,
    default: () => {},
  },
});
const details = computed(() => {
  return [
    {
      title: props.courseData?.name || "Course Name",
      class: "font-medium text-xl",
    },
    {
      title: "",
      class: "font-medium text-sm mb-5",
    },
    {
      title: "Course Description",
      class: "font-medium text-xl",
    },
    {
      title: props.courseData?.description || `Course Description Not Found`,
      class: "font-medium text-sm mb-3",
    },
  ];
});
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
</script>

<template>
  <div class="flex-col gap-5 bg-gray-200 p-5 rounded-md">
    <p
      v-for="item in details"
      :key="item.index"
      :class="item.class"
      class="text-gray-600"
    >
      {{ capitalizeFirstLetter(item.title) }}
    </p>
  </div>
</template>