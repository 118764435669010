<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import EnrollUnrollUsers from "./EnrollUnrollUsers.vue";
import FindUserComponent from "@/components/ui/MA/trainings/Courses/findUserComponent.vue";
import { MANIPULATE_USERS_ON_COURSE } from "@/store/modules/MA/staff-trainings/constants";
import Swal from "sweetalert2";

const showModal = ref(false);
const multipleSelection = ref([]);
const store = useStore();
const route = useRoute();
const router = useRouter();

const selection = computed({
  get() {
    return multipleSelection.value;
  },
  set(value) {
    multipleSelection.value = value;
  },
});
const submit = async () => {
  const fd = {
    data: {
      users: selection.value.map((item) => ({ user: item.id })),
      course: route.params.id,
    },
    url:
      title.value === "enroll users"
        ? "ma/training-courses/assign-to-users"
        : "ma/training-courses/unassign-users",
  };
  const response = await store.dispatch(
    `Trainings/${MANIPULATE_USERS_ON_COURSE}`,
    fd
  );
  if (response.status === 200) {
    Swal.fire({
      title: "Done",
      text: "Successfully Assigned Users to the Course",
      icon: "success",
    });
    router.push({
      name: "ma-staff-training",
    });
  }
};

const title = ref("enroll users");
</script>
<template>
  <div class="">
    <div class="flex gap-5">
      <div class="flex-grow">
        <div class="flex flex-col gap-5">
          <div
            @click="
              () => {
                showModal = true;
                title = 'unroll users';
              }
            "
            class="p-4 bg-gray-200 rounded-md cursor-pointer"
          >
            <div class="flex items-center">
              <span class="material-icons-outlined text-red text-5xl mr-3">
                remove_circle
              </span>
              Unroll User
            </div>
          </div>
          <div
            @click="
              () => {
                showModal = true;
                title = 'enroll users';
              }
            "
            class="p-4 bg-gray-200 rounded-md"
          >
            <div class="flex items-center cursor-pointer">
              <span class="material-icons-outlined text-teal text-5xl mr-3">
                add_circle
              </span>
              Enroll User
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-modal v-model:show="showModal" @confirm="submit">
      <template #title>
        <p class="capitalize">{{ title }}</p>
      </template>
      <template #content>
        <EnrollUnrollUsers v-model:selection="selection">
          <FindUserComponent />
        </EnrollUnrollUsers>
      </template>
      <template #successButton> Submit </template>
    </base-modal>
  </div>
</template>