<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { ElTable } from "element-plus";
import {
  FETCH_ALL_USERS_TO_ENROLL_UNROLL,
  GET_ALL_USERS_TO_ENROLL_AND_UNROLL_GETTER,
} from "@/store/modules/MA/staff-trainings/constants";

const store = useStore();
store.dispatch(`Trainings/${FETCH_ALL_USERS_TO_ENROLL_UNROLL}`);

const tableDataUsers = computed(() => {
  return store.getters[`Trainings/${GET_ALL_USERS_TO_ENROLL_AND_UNROLL_GETTER}`]
    ?.data;
});
const props = defineProps({
  selection: {
    type: Array,
    default: () => [],
  },
});
props;
const emits = defineEmits(["update:selection"]);
const handleSelectionChange = (val) => {
  emits("update:selection", val);
};
</script>
<template>
  <div class="bg-gray-300 rounded-md p-5">
    <slot></slot>
    <div class="mt-5 bg-white rounded-md">
      <el-table
        :data="tableDataUsers"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        header-row-class-name="bg-gray-200"
        header-cell-class-name="text-teal py-5"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column property="name" label="User Name" width="250">
          <template #default="scope">
            {{
              scope.row.profile.first_name + " " + scope.row.profile.last_name
            }}
          </template>
        </el-table-column>
        <el-table-column property="position" label="Position" width="200">
          <template #default> Position </template></el-table-column
        >
        <el-table-column property="department" label="Department" width="200">
          <template #default> Department </template>
        </el-table-column>

        <el-table-column property="location" label="Location" width="200">
          <template #default> Location </template></el-table-column
        >
      </el-table>
    </div>
  </div>
</template>