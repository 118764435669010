<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import BasicDetails from "@/components/ui/MA/trainings/Courses/BasicDetails.vue";
import CourseStats from "@/components/ui/MA/trainings/Courses/CourseStats.vue";
import FindUserComponent from "@/components/ui/MA/trainings/Courses/findUserComponent.vue";
import UserOprations from "@/components/ui/MA/trainings/Courses/userOperations.vue";
import moment from "moment";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
import {
  FETCH_A_SINGLE_COURSE_ACTION,
  GET_A_SINGLE_COURSE_GETTER,
  FETCH_ALL_COURSES_ACTION,
  UPDATE_DATES_FOR_ENROLLED_USERS,
} from "@/store/modules/MA/staff-trainings/constants";

const store = useStore();
const route = useRoute();
const router = useRouter();

const showEditModal = ref(false);
const startingDate = ref(null);
const dueDate = ref(null);
const userId = ref(null);
const refreshTable = ref(0);

store.dispatch(`Trainings/${FETCH_A_SINGLE_COURSE_ACTION}`, {
  course: route.params.id,
});

store.dispatch(`Trainings/${FETCH_ALL_COURSES_ACTION}`);

const openModal = (event) => {
  showEditModal.value = true;
  userId.value = event.id;
  startingDate.value = event.assignDate;
  dueDate.value = event.dueDate;
};

const editUserDates = async () => {
  await store.dispatch(`Trainings/${UPDATE_DATES_FOR_ENROLLED_USERS}`, {
    user: userId.value,
    course: currentCourse.value?.id,
    dates: [
      {
        start_date: startingDate.value,
        due_date: dueDate.value,
      },
    ],
  });
  await store.dispatch(`Trainings/${FETCH_A_SINGLE_COURSE_ACTION}`, {
    course: route.params.id,
  });
  showEditModal.value = false;
  refreshTable.value++;
};

const tabledropdownOptions = [
  {
    type: "link",
    linkName: "ma-view-a-course-user",
    paramName: "id",
    label: "View",
  },
  {
    type: "action",
    label: "Edit Dates",
    action: openModal,
  },
];

const tableHeader = [
  { title: "Enrolled User", prop: "name", id: "fullnameWithoutUser" },
  { title: "Position", prop: "profile.primary_role", id: "position" },
  { title: "Department", prop: "department_id", id: "department" },
  { title: "Location", prop: "location", id: "location" },
  { title: "Assigned Date", prop: "pivot.start_date", id: "assigned_on" },
  { title: "Deadline", prop: "pivot.due_date", id: "due_date" },
  { title: "Overdue Days", prop: "overdue", id: "overdue" },
  { title: "Status", prop: "progress", id: "status" },
  {
    title: "View Profile",
    prop: "user",
    id: "actions",
  },
];

const currentCourse = computed(() => {
  return store.getters[`Trainings/${GET_A_SINGLE_COURSE_GETTER}`];
});
const reRoute = () => {
  router.back();
};
</script>
<template>
  <div class="w-full h-full p-5">
    <div
      class="flex items-center text-teal ml-5 mb-3 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div class="flex px-5 gap-5">
      <div class="w-3/5">
        <BasicDetails :courseData="currentCourse || {}" />
      </div>
      <div class="flex-grow">
        <CourseStats
          :assignedEmployees="currentCourse?.enrolled_users_count"
          :completedEmployees="currentCourse?.course_progress_count"
        />
      </div>
    </div>
    <div class="flex p-5 gap-5">
      <div class="w-3/5">
        <FindUserComponent />
      </div>
      <div class="flex-grow">
        <UserOprations :courseData="currentCourse || {}" />
      </div>
    </div>
    <div
      class="px-5 h-80 overflow-auto bg-gray-200 rounded-md p-5 mx-4"
      :key="refreshTable"
    >
      <p class="text-gray-600 font-medium mb-4 text-xl">
        Enrolled Users to Course
      </p>
      <el-table
        header-row-class-name="bg-gray-200"
        header-cell-class-name="text-teal py-5"
        :cell-style="rowClass && { padding: '10px' }"
        :row-class-name="rowClass && `border-t ${rowClass}`"
        :default-sort="{ prop: 'date', order: 'descending' }"
        class="
          w-full
          rounded-xl
          overflow-hidden
          bg-gray-100
          flex flex-grow
          h-full
        "
        :data="
          currentCourse?.enrolled_users?.length
            ? currentCourse?.enrolled_users
            : []
        "
        style="width: 100%"
      >
        <el-table-column
          v-for="item in tableHeader"
          :key="item.index"
          :prop="item.prop"
          :label="item.title"
          class="text-center"
          :sortable="item.id != 'actions' ? true : false"
        >
          <template #default="scope">
            <div v-if="item.id === 'moduleslength'">
              {{ scope.row.modules.length }}
            </div>
            <div v-if="item.id === 'department'">
              {{ scope.row.department.name }}
            </div>
            <div v-if="item.id === 'location'">
              Rushden Medical<br />
              Center
            </div>
            <div v-if="item.id === 'assigned_on'">
              <div v-if="scope.row?.pivot?.start_date">
                {{
                  new Date(scope.row?.pivot?.start_date).toLocaleDateString(
                    "en-GB"
                  )
                }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div v-if="item.id === 'due_date'">
              <div v-if="scope.row?.pivot?.due_date">
                {{
                  new Date(scope.row?.pivot?.due_date).toLocaleDateString(
                    "en-GB"
                  )
                }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div v-if="item.id === 'av_completion_time'">4 days</div>
            <div v-if="item.id === 'overdue'">
              <div
                v-if="
                  moment().diff(moment(scope.row?.pivot?.due_date), `days`) > 0
                "
              >
                {{ moment().diff(moment(scope.row?.pivot?.due_date), "days") }}
                days
              </div>
              <div v-else>0 days</div>
            </div>

            <div class="" v-if="item.id === 'status'">
              <div
                class="
                  inline-flex
                  items-center
                  justify-center
                  overflow-hidden
                  bottom-5
                "
                v-if="
                  moment().diff(moment(scope.row?.pivot?.due_date), `days`) > 0
                "
              >
                Overdue
              </div>
              <div
                class="
                  inline-flex
                  items-center
                  justify-center
                  overflow-hidden
                  rounded-full
                  bottom-5
                "
                v-else
              >
                <!-- Building a Progress Ring: https://css-tricks.com/building-progress-ring-quickly/ -->
                In-progress
              </div>
            </div>
            <div class="" v-if="item.id === 'fullnameWithoutUser'">
              {{
                scope.row.profile.first_name + " " + scope.row.profile.last_name
              }}
            </div>
            <div class="" v-if="item.id === 'date'">
              {{ moment(new Date(scope.row.created_at)).format("ll") }}
            </div>
            <div class="" v-if="item.id === 'actions'">
              <table-options-dropdown
                :id="scope.row.id"
                :options="tabledropdownOptions"
                :dynamicParam="scope.row.id"
                :assignDate="scope.row?.pivot?.start_date"
                :dueDate="scope.row?.pivot?.due_date"
              >
                <span class="material-icons"> more_horiz </span>
              </table-options-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <base-modal
      v-model:show="showEditModal"
      @confirm="editUserDates"
      :showCancel="true"
      :showConfirm="true"
    >
      <template #title>Edit Course Span for User</template>
      <template #content>
        <div class="w-11.5/12 m-auto">
          <div :key="index" class="sm:my-1">
            <div class="w-full md:w-1/2">
              <label for="startDate" class="w-3/12">Starting Date:</label>
              <input
                v-model="startingDate"
                name="startDate"
                type="date"
                class="
                  w-9/12
                  rounded-lg
                  border-2 border-teal
                  text-teal
                  bg-white
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <div :key="index" class="sm:my-1">
            <div class="w-full md:w-1/2">
              <label for="startDate" class="w-3/12">Due Date:</label>
              <input
                v-model="dueDate"
                name="startDate"
                type="date"
                class="
                  w-9/12
                  rounded-lg
                  border-2 border-teal
                  text-teal
                  bg-white
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
        </div>
      </template>
      <template #successButton> Save </template>
    </base-modal>
  </div>
</template>