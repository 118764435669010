<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  assignedEmployees: {
    type: Number,
    requried: false,
  },
  completedEmployees: {
    type: Number,
    requried: false,
  },
});

const percentage = computed(() => {
  console.log(
    " adfsfafdf: ",
    props.completedEmployees,
    props.assignedEmployees
  );
  return props.completedEmployees / props.assignedEmployees;
});

const items = [
  {
    type: "single",
    title: props.assignedEmployees,
    description: "Assigned",
  },
  {
    type: "multi",
    title: [props.completedEmployees, props.assignedEmployees],
    description: "Completed",
  },

  {
    title: percentage.value + `%`,
    description: "Percentage",
    text_class: "text-teal",
    type: "single",
  },
];
</script>
<template>
  <div class="bg-gray-200 p-5 rounded-md h-full flex flex-col">
    <p class="text-xl font-medium text-gray-600">Course Metrics:</p>
    <div class="flex flex-grow flex-col justify-center">
      <div class="">
        <div class="flex gap-5 justify-evenly">
          <div class="text-center" v-for="item in items" :key="item.index">
            <div class="h-32 bg-white rounded-md px-4 flex items-center">
              <p
                v-if="item.type === 'single'"
                class="text-2xl font-medium align-middle justify-center pl-2"
                :class="item.text_class ? item.text_class : 'text-red'"
              >
                {{ item.title }}
              </p>
              <div v-else class="w-full">
                <div class="grid grid-cols-1 divide-y">
                  <div
                    :class="[
                      {
                        'text-gray-600 border-b pb-2 font-medium text-2xl':
                          index < item.title.length,
                      },
                      {
                        'text-red':
                          index === 0 &&
                          ((item.title[0] / item.title[1]) * 100).toFixed(0) <
                            33,
                      },
                      {
                        'text-orange':
                          index === 0 &&
                          ((item.title[0] / item.title[1]) * 100).toFixed(0) >
                            33 &&
                          ((item.title[0] / item.title[1]) * 100).toFixed(0) <
                            51,
                      },
                      {
                        'text-teal':
                          index === 0 &&
                          ((item.title[0] / item.title[1]) * 100).toFixed(0) >
                            66,
                      },
                      {
                        'text-gray-600 font-medium pt-2 text-2xl':
                          index == item.title.length - 1,
                      },
                    ]"
                    v-for="(title, index) in item.title"
                    :key="'title' + index"
                  >
                    {{ title }}
                  </div>
                </div>
              </div>
            </div>
            <p class="font-medium text-gray-500 mt-2">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>