<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const checkClick = () => {
  console.log("check click");
};

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  options: {
    type: [Object, Array],
    required: true,
  },
  dynamicParam: {
    type: Number,
  },
  dynamicParam2: {
    type: Number,
  },
  assignDate: {
    type: Date,
  },
  dueDate: {
    type: Date,
  },
  attachment: {
    type: String,
  },
});
</script>
<template>
  <el-dropdown>
    <span class="material-icons"> more_horiz </span>
    <template #dropdown @click="checkClick()">
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="option in props.options"
          :key="option.label"
          @click="
            option.type !== 'link'
              ? option.action({
                  id: props.id,
                  assignDate: props?.assignDate,
                  dueDate: props?.dueDate,
                  attachment: props?.attachment,
                })
              : dynamicParam2
              ? router.push({
                  name: option.linkName,
                  params: {
                    [option.paramName2]: dynamicParam2,
                    [option.paramName]: dynamicParam,
                  },
                })
              : router.push({
                  name: option.linkName,
                  params: { [option.paramName]: dynamicParam },
                })
          "
        >
          {{ option.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
