<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const showModal = ref(false);
const loadUser = async () => {
  let filter = []
    .concat(...searchCriterias)
    .map(
      (item) => item.value !== "" && { filter: item.name, value: item.value }
    )
    .filter(Boolean);

  const { data } = await store.dispatch("REgeneralModule/loadUsers", filter);
  console.log(data);
};

const searchCriterias = [
  [
    {
      title: "First Name",
      name: "first_name",
      value: "",
    },
    {
      title: "Last Name",
      name: "last_name",
      value: "",
    },
  ],
  [
    {
      title: "Start Date",
      name: "start_date",
      value: "",
    },
    {
      title: "End Date",
      name: "end_date",
      value: "",
    },
  ],
  [
    {
      title: "Job Role",
      name: "job_role",
      value: "",
    },
  ],
];
</script>
<template>
  <div class="bg-gray-200 p-5 rounded-md">
    <p></p>
    <div class="flex gap-5 justify-between">
      <div
        class="flex flex-col"
        v-for="section in searchCriterias"
        :key="section.index"
      >
        <div class="flex flex-col" v-for="item in section" :key="item">
          <label :for="item.title" class="mt-2">{{ item.title }}</label>
          <input
            class="rounded-md"
            type="text"
            :id="item.title"
            v-model="item.value"
          />
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <base-button backgroundColour="bg-orange hover:bg-orange-light"
          >Reset</base-button
        >
        <base-button @click="loadUser">Search</base-button>
      </div>
    </div>
    <base-modal v-model:show="showModal">
      <template #title>Manage Users</template>
      <template #content>
        this is a modal to arrange the users in the list of courses
      </template>
      <template #successButton> Save </template>
    </base-modal>
  </div>
</template>